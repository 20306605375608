// @ts-strict-ignore
import Immutable from "immutable";

import { keyConverter } from "services/key-converter";
import RecordClassCreator, {
  type ManyResourcePayload,
  type RecordAttributeMapper,
  type SingleResourcePayload,
} from "services/record";

import {
  type ChannelIntegrationProps,
  ChannelIntegrationRecord,
} from "./helpers";

export interface ChannelAttributes {
  name: string;
  enabled: boolean;
  appId?: string;
  apiKey?: string;
  apiSecret?: string;
  integrations?: Immutable.List<ChannelIntegrationRecord>;
  zendeskSubdomain?: string;
  primaryWebIntegration?: string | null;
}

export interface ChannelObj {
  _id: string;
  app_id: string;
  api_secret: string;
  api_key: string;
  name: string;
  enable: boolean;
  integrations: Immutable.List<ChannelIntegrationRecord>;
  zendesk_subdomain: string;
  primary_web_integration?: string | null;
}

export class Channel extends RecordClassCreator<ChannelAttributes>({
  type: "channel",
  enabled: false,
  name: "",
  appId: "",
  apiKey: "",
  apiSecret: "",
  integrations: Immutable.List(),
  zendeskSubdomain: "",
  primaryWebIntegration: null,
}) {
  static attributeMapper: RecordAttributeMapper<ChannelAttributes> = {
    integrations: (integrations: ChannelIntegrationProps[]) =>
      integrations.map(
        (integration) =>
          new ChannelIntegrationRecord({
            id: integration.id,
            defaultLanguage: integration.defaultLanguage,
            multiBotChannelEnabled: integration.multiBotChannelEnabled,
            attributes: integration.attributes,
          }),
      ),
  };

  static getFetchOneUrl(channelName: string): string {
    return `/channels/${channelName}`;
  }

  static getFetchManyUrl(): string {
    return "/channels";
  }

  static getCreateOneUrl(): string {
    return "/channels/";
  }

  static getDeleteOneURL(channelId: string): string {
    return `/channels/${channelId}`;
  }

  static resolveFetchOneResult(payload: {
    channel: { _id: string };
  }): SingleResourcePayload {
    return {
      data: {
        ...payload.channel,
        _id: payload.channel._id,
        _type: "channel",
      },
    };
  }

  static resolveFetchManyResult(payload: {
    channels: ChannelObj[];
  }): ManyResourcePayload {
    return {
      data: payload.channels.map((channel) => ({
        ...channel,
        _id: channel._id,
        _type: "channel",
        appId: channel.app_id,
        apiSecret: channel.api_secret,
        apiKey: channel.api_key,
        integrations: channel.integrations,
        zendeskSubdomain: channel.zendesk_subdomain,
        primaryWebIntegration: channel.primary_web_integration,
      })),
    };
  }

  get invalidFields(): Immutable.List<string> {
    const invalid = [];

    if (!this.appId) {
      invalid.push("appId");
    }

    if (!this.apiKey) {
      invalid.push("apiKey");
    }

    if (!this.apiSecret) {
      invalid.push("apiSecret");
    }

    return Immutable.List(invalid);
  }

  get isValid(): boolean {
    return this.invalidFields.size === 0;
  }

  serialize(): { [key: string]: unknown } {
    const base = keyConverter(this.attributes, "underscore");
    const changes = keyConverter(this.changedAttributes, "underscore");

    return {
      ...base,
      ...changes,
    };
  }
}
